import "./admin-home.page.css";
import Sidebar from "../../../Components/Admin/sidebar/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { createTemplate } from "../../../Services/networkcall.Admin.service";
// import {
//   getCategory,
//   getSubCategory,
// } from "../../../Services/netwokcall.service";

const AdminHome = () => {
  const [image, setImage] = useState({});
  // useEffect(()=>{
  //   getCategory()
  //   // getSubCategory()
  // },[])

  const styleForm = useFormik({
    initialValues: {
      primaryColor: "",
      secondaryColor: "",
      primaryFontColor: "",
      secondaryFontColor: "",
      footerWeekdays: "",
      footerWeekends: "",
      footerDescription: "",
      logo: "",
      title: "",
      playstore: "",
      appstore: "",
      facebook: "",
      instagram: "",
      twitter: "",
      whatsapp: "",
      telephone: "",
    },
    validationSchema: Yup.object({
      primaryColor: Yup.string().min(7, "Must be 7 character"),
      secondaryColor: Yup.string().min(7, "Must be 7 characters"),
      primaryFontColor: Yup.string().min(7, "Must be 7 characters"),
      secondaryFontColor: Yup.string().min(7, "Must be 7 characters"),
      footerWeekdays: Yup.string().min(15, "Must be 15 character"),
      footerWeekends: Yup.string().min(15, "Must be 15 characters"),
      footerDescription: Yup.string(),
      logo: Yup.string().max(1000, "Must be 1000 characters"),
      title: Yup.string(),
      playstore: Yup.string().max(1000, "Must be 1000 characters"),
      appstore: Yup.string().max(1000, "Must be 1000 characters"),
      facebook: Yup.string().max(1000, "Must be 1000 characters"),
      instagram: Yup.string().max(1000, "Must be 1000 characters"),
      twitter: Yup.string().max(1000, "Must be 1000 characters"),
      whatsapp: Yup.string().max(1000, "Must be 1000 characters"),
      telephone: Yup.string().max(50, "Must be 50 characters"),
    }),
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2), image);
      await createTemplate(values);
    },
  });

  return (
    <div className="admin-home-main-container">
      <Sidebar />
      <div className="admin-home-container">
        <form className="form-container" onSubmit={styleForm.handleSubmit}>
          <div className="contact-form-title">Customize Template Style</div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="primaryColor">
              primaryColor
            </label>
            <input
              className="form-input"
              id="primaryColor"
              name="primaryColor"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.primaryColor}
            />
            {styleForm.touched.primaryColor && styleForm.errors.primaryColor ? (
              <div className="contact-error">
                {styleForm.errors.primaryColor}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="secondaryColor">
              secondaryColor
            </label>
            <input
              className="form-input"
              id="secondaryColor"
              name="secondaryColor"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.secondaryColor}
            />
            {styleForm.touched.secondaryColor &&
            styleForm.errors.secondaryColor ? (
              <div className="contact-error">
                {styleForm.errors.secondaryColor}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="primaryFontColor">
              primaryFontColor
            </label>
            <input
              className="form-input"
              id="primaryFontColor"
              name="primaryFontColor"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.primaryFontColor}
            />
            {styleForm.touched.primaryFontColor &&
            styleForm.errors.primaryFontColor ? (
              <div className="contact-error">
                {styleForm.errors.primaryFontColor}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="secondaryFontColor">
              secondaryFontColor
            </label>
            <input
              className="form-text"
              id="secondaryFontColor"
              name="secondaryFontColor"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.secondaryFontColor}
            />
            {styleForm.touched.secondaryFontColor &&
            styleForm.errors.secondaryFontColor ? (
              <div className="contact-error">
                {styleForm.errors.secondaryFontColor}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="footerWeekdays">
              footerWeekdays
            </label>
            <input
              className="form-input"
              id="footerWeekdays"
              name="footerWeekdays"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.footerWeekdays}
            />
            {styleForm.touched.footerWeekdays &&
            styleForm.errors.footerWeekdays ? (
              <div className="contact-error">
                {styleForm.errors.footerWeekdays}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="footerWeekends">
              footerWeekends
            </label>
            <input
              className="form-input"
              id="footerWeekends"
              name="footerWeekends"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.footerWeekends}
            />
            {styleForm.touched.footerWeekends &&
            styleForm.errors.footerWeekends ? (
              <div className="contact-error">
                {styleForm.errors.footerWeekends}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="footerDescription">
              footerDescription
            </label>
            <input
              className="form-input"
              id="footerDescription"
              name="footerDescription"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.footerDescription}
            />
            {styleForm.touched.footerDescription &&
            styleForm.errors.footerDescription ? (
              <div className="contact-error">
                {styleForm.errors.footerDescription}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="logo">
              logo
            </label>
            <input
              className="form-text"
              id="logo"
              name="logo"
              type="file"
              onChange={(event) => {
                // console.log("image", event.currentTarget.files[0]);
                setImage(event.currentTarget.files[0]);
                // productForm.setFieldValue("productImage", event.currentTarget.files[0]);
              }}
            />
            {styleForm.touched.logo && styleForm.errors.logo ? (
              <div className="contact-error">{styleForm.errors.logo}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="title">
              title
            </label>
            <input
              className="form-input"
              id="title"
              name="title"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.title}
            />
            {styleForm.touched.title && styleForm.errors.title ? (
              <div className="contact-error">{styleForm.errors.title}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="playstore">
              playstore
            </label>
            <input
              className="form-input"
              id="playstore"
              name="playstore"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.playstore}
            />
            {styleForm.touched.playstore && styleForm.errors.playstore ? (
              <div className="contact-error">{styleForm.errors.playstore}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="appstore">
              appstore
            </label>
            <input
              className="form-input"
              id="appstore"
              name="appstore"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.appstore}
            />
            {styleForm.touched.appstore && styleForm.errors.appstore ? (
              <div className="contact-error">{styleForm.errors.appstore}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="facebook">
              facebook
            </label>
            <input
              className="form-text"
              id="facebook"
              name="facebook"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.facebook}
            />
            {styleForm.touched.facebook && styleForm.errors.facebook ? (
              <div className="contact-error">{styleForm.errors.facebook}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="instagram">
              instagram
            </label>
            <input
              className="form-text"
              id="instagram"
              name="instagram"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.instagram}
            />
            {styleForm.touched.instagram && styleForm.errors.instagram ? (
              <div className="contact-error">{styleForm.errors.instagram}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="twitter">
              twitter
            </label>
            <input
              className="form-input"
              id="twitter"
              name="twitter"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.twitter}
            />
            {styleForm.touched.twitter && styleForm.errors.twitter ? (
              <div className="contact-error">{styleForm.errors.twitter}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="whatsapp">
              whatsapp
            </label>
            <input
              className="form-input"
              id="whatsapp"
              name="whatsapp"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.whatsapp}
            />
            {styleForm.touched.whatsapp && styleForm.errors.whatsapp ? (
              <div className="contact-error">{styleForm.errors.whatsapp}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="telephone">
              telephone
            </label>
            <input
              className="form-input"
              id="telephone"
              name="telephone"
              type="text"
              onChange={styleForm.handleChange}
              onBlur={styleForm.handleBlur}
              value={styleForm.values.telephone}
            />
            {styleForm.touched.telephone && styleForm.errors.telephone ? (
              <div className="contact-error">{styleForm.errors.telephone}</div>
            ) : null}
          </div>

          <button className="contact-submit" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default AdminHome;
