// import updateSelectedFileDetails from "./SelectedFile/selected_file";
import { combineReducers } from "redux";
import { setStyle } from "./Style/style";
import { setCategory, setSubCategory } from "./Category/category";
import { setProduct } from "./Product/product";
import { setHome } from "./Home/home";
import { setAbout } from "./About/about";
import { setContact } from "./Contact/contact";
import { setBranch } from "./Branch/branch";
import { setDiscount } from "./Discount/discount";
import { setMenu } from "./Menu/menu";


const allReducers = combineReducers({
  style: setStyle,
  category : setCategory,
  subcategory : setSubCategory,
  products : setProduct,
  home : setHome,
  about : setAbout,
  contact : setContact,
  branch : setBranch,
  discount : setDiscount,
  menu : setMenu

  // updateSelectedFileDetails,
  // can add all the reducers here
});

export default allReducers;
