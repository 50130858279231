export const AdminSiteMap = {
  AdminPage: {
    title: "Admin Dashboard",
    path: "/admin",
    description: "template admin page",
  },
  AdminProductPage: {
    title: "Admin Product",
    path: "/admin/product",
    description: "template admin product page",
  },
  AdminCategoryPage: {
    title: "Admin Category",
    path: "/admin/category",
    description: "template admin category page",
  },
  AdminProductReadPage: {
    title: "Admin Product",
    path: "/admin/product/read",
    description: "template admin product read page",
  },
  AdminContactPage: {
    title: "Admin Contact",
    path: "/admin/contact",
    description: "template admin contact page",
  },
  AdminHomePage: {
    title: "Admin Home",
    path: "/admin/home",
    description: "template admin home page",
  },
  AdminAboutPage: {
    title: "Admin About",
    path: "/admin/about",
    description: "template admin about page",
  },
  AdminBranchPage: {
    title: "Admin branch",
    path: "/admin/branch",
    description: "template admin branch page",
  },
  AdminDiscountPage: {
    title: "Admin discount",
    path: "/admin/discount",
    description: "template admin discount page",
  },
};
