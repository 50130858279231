import "./contact-section-1.css"
// import browse from "../../../../Assets/Client/browse.png"


const ContactSection1 = ({image, description}) => {
  return (
    <section className="contactsection1-main-container">
        <div className="contactsection1r-left-container">
            <img className="contactsection1-image" src={image} alt="" />
        </div>
        <div className="contactsection1-right-container">
            <div className="contactsection1-right-title">Contact Us</div>
            <div className="contactsection1-right-content">{description}</div>
        </div>
   </section>
  )
}
export default ContactSection1