import { SET_ABOUT } from "../../actions/about.action";

export const setAbout = (state = null, action) => {
    switch (action.type) {
      case SET_ABOUT:
        return action.payload;
      default:
        return state;
    }
  };

