export const SET_CATEGORY = "SET_CATEGORY"
export const SET_SUB_CATEGORY = "SET_SUB_CATEGORY"

export const setCategory = (payload) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY,
    payload: payload,
  });
};


export const setSubCategory = (payload) => (dispatch) => {
  dispatch({
    type: SET_SUB_CATEGORY,
    payload: payload,
  });
};