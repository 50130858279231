import "./header.css";
import { useNavigate } from "react-router-dom";
import { ClientSiteMap } from "../../../Routes/Client/siteMap";
import { useEffect } from "react";
// import logo from "../../../Assets/Client/logo.png";
import Dropdown from "../Custom-Menu-Dropdown/dropdown";
import { useSelector } from "react-redux";



const Header = () => {
  const style = useSelector((state) => state.style)
  useEffect(()=>{
    if(style !== null){
      var root = document.querySelector(':root');
      root.style.setProperty('--primary-background-color', style.primaryColor);
      root.style.setProperty('--template-color', style.secondaryColor);
      root.style.setProperty('--primary-font-color', style.primaryFontColor);
      root.style.setProperty('--secondary-font-color', style.secondaryFontColor);
    }
  },[style])

  const navigate = useNavigate();

  useEffect(() => {
    window.onscroll = function () {
      scroll();
    };
  }, []);

  const routeToHome = () => {
    navigate(ClientSiteMap.HomePage.path, { replace: true });
  };


  const scroll = () => {
    // console.log("screen width",window.screen.width);
    if(window.screen.width > 420){
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("header").style.height = "8vh";
        document.getElementById("logo").style.height = "30px";
      } else {
        document.getElementById("header").style.height = "15vh";
        document.getElementById("logo").style.height = "80px";
      }
    }
  };

  return (
    <header className="header-main-container" id="header">
      <div
        className="header-logo-container"
        onClick={() => {
          routeToHome();
        }}
        >
        <img className="logo" id="logo" src={
                style === null ? "loading" : style.logo
              } alt="" />
        <div>{
          style === null ? "loading" : style.title
        }</div>
      </div>
      <div className="header-menu-container">
        <Dropdown/>
      </div>
    </header>
  );
};
export default Header;
