import "./map.css"

const Map = ({link}) => {
  return (
    <section className="map-main-container">
        <iframe className="map" title="address" src={link} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        {/* <iframe className="map" title="address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.394564053124!2d80.21671641438718!3d13.137493690747185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265cfad484251%3A0xca12e783c5fc4f61!2sstartup%20booster!5e0!3m2!1sen!2sin!4v1661756020098!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
    </section>
  )
}
export default Map 