// import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';

export let resultJson = [
  {
    "label": "Home",
    "key": "home"
  },
  {
    "label": "Products",
    "key": "products",
    "children": [
      
    ]
  },
  {
    "label": "Rewards",
    "key": "discount"
  },
  {
    "label": "Contact Us",
    "key": "contact"
  }
]