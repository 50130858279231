import { config } from "../Config/server.config";
import { store } from "../index";
import uuid from "react-uuid";

// imports from redux - actions
import { setStyle } from "../Redux/actions/style.action";

// network call default options
const fetchOptions = (
  method,
  formdata = false,
  raw = null,
  referCode = null
) => {
  // network call default header
  if (formdata === true) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // TODO write conditions for get method(get method does not have body)
    if (method === "GET") {
      const requestOptions = {
        method: method,
        // headers: myHeaders,
        redirect: "follow",
      };
      return requestOptions;
    } else {
      const requestOptions = {
        method: method,
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      return requestOptions;
    }
  } else {
    const requestOptions = {
      method: method,
      redirect: "follow",
    };
    return requestOptions;
  }
};

// To create new category, call this function

export const createCategory = async (
  categoryName,
  categoryDescription,
  categoryImage
) => {
  // // console.log("style_id", config.styleId);

  let formdata = new FormData();
  formdata.append("categoryId", uuid());
  formdata.append("categoryName", categoryName);
  formdata.append("categoryDescription", categoryDescription);
  formdata.append("categoryImage", categoryImage);
  var requestOptions = {
    method: "POST",
    body: formdata,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  const url = `${config.server_url}/category/create`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        resolve("success");
      });
  });
};

// To delete a specific category, call this function

export const deleteCategory = async (categoryId) => {
  var raw = JSON.stringify({
    categoryId: categoryId,
  });
  const option = fetchOptions("POST", true, raw, null);
  // var raw = formData;
  // const option = fetchOptions("POST", true, formData);
  const url = `${config.server_url}/category/deleteCategory`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then(async (res) => await res.text())
      .then((data) => {
        console.log("from network", JSON.parse(data));
        // const json_data = JSON.parse(data);
        resolve("success");
      });
  });
};

// To delete a specific category, call this function

export const deleteSubCategory = async (categoryId, subcategoryId) => {
  var raw = JSON.stringify({
    categoryId: categoryId,
    subcategoryId: subcategoryId,
  });
  const option = fetchOptions("POST", true, raw, null);
  // var raw = formData;
  // const option = fetchOptions("POST", true, formData);
  const url = `${config.server_url}/category/deleteSubCategory`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then(async (res) => await res.text())
      .then((data) => {
        console.log("from network", JSON.parse(data));
        // const json_data = JSON.parse(data);
        resolve("success");
      });
  });
};

// To create new style, call this function

export const createTemplate = async (values) => {
  // // console.log("style_id", config.styleId);
  let formdata = new FormData();
  formdata.append("styleId", 1);
  formdata.append("primaryColor", values.primaryColor);
  formdata.append("secondaryColor", values.secondaryColor);
  formdata.append("primaryFontColor", values.primaryFontColor);
  formdata.append("secondaryFontColor", values.secondaryFontColor);
  formdata.append("footerWeekdays", values.footerWeekdays);
  formdata.append("footerWeekends", values.footerWeekends);
  formdata.append("footerDescription", values.footerDescription);
  formdata.append("logo", values.logo);
  formdata.append("title", values.title);
  formdata.append("playstore", values.playstore);
  formdata.append("appstore", values.appstore);
  formdata.append("facebook", values.facebook);
  formdata.append("instagram", values.instagram);
  formdata.append("twitter", values.twitter);
  formdata.append("whatsapp", values.whatsapp);
  formdata.append("telephone", values.telephone);
  var requestOptions = {
    method: "POST",
    body: formdata,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  // const option = fetchOptions("POST", true, formdata, null);
  const url = `${config.server_url}/style/create`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        resolve("success");
      });
  });
};

// To create new Sub category, call this function

export const createSubCategory = async (categoryId, subcategory) => {
  // // console.log("style_id", config.styleId);
  var raw = JSON.stringify({
    categoryId: categoryId,
    subcategory: {
      subcategoryId: uuid(),
      name: subcategory,
    },
  });
  const option = fetchOptions("POST", true, raw, null);
  const url = `${config.server_url}/category/update`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        resolve("success");
      });
  });
};

// To create new Sub category, call this function

export const createProduct = async (
  productName,
  productDescription,
  productCategoryId,
  productSubCategoryId,
  productImage
) => {
  // // console.log("style_id", config.styleId);
  let formdata = new FormData();
  formdata.append("productId", uuid());
  formdata.append("productName", productName);
  formdata.append("productDescription", productDescription);
  formdata.append("productCategoryId", productCategoryId);
  formdata.append("productSubcategoryId", productSubCategoryId);
  formdata.append("productImage", productImage);
  // var raw = formData;

  var requestOptions = {
    method: "POST",
    body: formdata,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  // const option = fetchOptions("POST", true, formData);
  const url = `${config.server_url}/product/create`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(async (res) => await res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        // const json_data = JSON.parse(data);
        // store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

// To delete a specific product, call this function

export const deleteProduct = async (productId) => {
  var raw = JSON.stringify({
    productId: productId,
  });
  const option = fetchOptions("POST", true, raw, null);
  // var raw = formData;
  // const option = fetchOptions("POST", true, formData);
  const url = `${config.server_url}/product/delete`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then(async (res) => await res.text())
      .then((data) => {
        console.log("from network", JSON.parse(data));
        // const json_data = JSON.parse(data);
        resolve("success");
      });
  });
};

// To create new Contact, call this function

export const createContact = async (
  contactDescription,
  location,
  contactAddress,
  contactPhone,
  contactEmail,
  contactImage
) => {
  // // console.log("style_id", config.styleId);
  let formData = new FormData();
  formData.append("contactDescription", contactDescription);
  formData.append("location", location);
  formData.append("contactAddress", contactAddress);
  formData.append("contactPhone", contactPhone);
  formData.append("contactEmail", contactEmail);
  formData.append("contactImage", contactImage);
  var requestOptions = {
    method: "POST",
    body: formData,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  const url = `${config.server_url}/contact/create`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        resolve("success");
      });
  });
};

export const deleteContact = async () => {
  const option = fetchOptions("POST");
  const url = `${config.server_url}/contact/delete`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

export const createUpdateHome = async (
  homeCarouselImages,
  homeImage,
  homeTrendingFirst,
  homeTrendingSecond,
  homeTrendingThird,
  homeVideoTitle,
  homeVideoUrl
) => {
  // // console.log("style_id", config.styleId);
  let formData = new FormData();
  // eslint-disable-next-line
  for (let i in homeCarouselImages) {
    formData.append("homeCarousel", homeCarouselImages);
  }
  formData.append("homeImage", homeImage);
  formData.append("homeTrending[0]", homeTrendingFirst);
  formData.append("homeTrending[1]", homeTrendingSecond);
  formData.append("homeTrending[2]", homeTrendingThird);
  formData.append("homeVideoTitle", homeVideoTitle);
  formData.append("homeVideoUrl", homeVideoUrl);
  var requestOptions = {
    method: "POST",
    body: formData,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  const url = `${config.server_url}/home/create`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        // const json_data = JSON.parse(data);
        // store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

// To update only the content of the home, call this function
export const changeHomeContent = async (
  homeTrending,
  homeVideoTitle,
  homeVideoUrl
) => {
  var raw = JSON.stringify({
    homeTrending: homeTrending,
    homeVideoTitle: homeVideoTitle,
    homeVideoUrl: homeVideoUrl,
  });
  const option = fetchOptions("POST", true, raw, null);
  const url = `${config.server_url}/home/updateContent`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        // const json_data = JSON.parse(data);
        // store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

// To update only the images of the home, call this function
export const changeHomeImages = async (homeCarousel, homeImage) => {
  let formData = new FormData();
  formData.append("homeCarousel", homeCarousel);
  formData.append("homeImage", homeImage);
  var requestOptions = {
    method: "POST",
    body: formData,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  const url = `${config.server_url}/home/updateImages`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        // const json_data = JSON.parse(data);
        // store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

export const deleteHome = async () => {
  const option = fetchOptions("POST");
  const url = `${config.server_url}/home/delete`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

export const createAbout = async (
  index,
  aboutTitle,
  aboutDescription,
  aboutImage
) => {
  // // console.log("style_id", config.styleId);
  let formData = new FormData();
  formData.append("index", index);
  formData.append("aboutTitle", aboutTitle);
  formData.append("aboutDescription", aboutDescription);
  formData.append("aboutImage", aboutImage);
  var requestOptions = {
    method: "POST",
    body: formData,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  const url = `${config.server_url}/about/create`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

export const deleteAbout = async () => {
  const option = fetchOptions("POST");
  const url = `${config.server_url}/about/delete`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};
export const createBranch = async (
  index,
  branchTitle,
  branchDescription,
  branchImage,
  branchLocation
) => {
  // // console.log("style_id", config.styleId);
  let formData = new FormData();
  formData.append("index", index);
  formData.append("branchTitle", branchTitle);
  formData.append("branchDescription", branchDescription);
  formData.append("branchImage", branchImage);
  formData.append("branchLocation", branchLocation);
  var requestOptions = {
    method: "POST",
    body: formData,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  const url = `${config.server_url}/branch/create`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

export const deleteBranch = async () => {
  const option = fetchOptions("POST");
  const url = `${config.server_url}/branch/delete`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

export const createDiscount = async (
  index,
  discountTitle,
  discountDescription,
  discountImage
) => {
  // // console.log("style_id", config.styleId);
  let formData = new FormData();
  formData.append("index", index);
  formData.append("discountTitle", discountTitle);
  formData.append("discountDescription", discountDescription);
  formData.append("discountImage", discountImage);
  var requestOptions = {
    method: "POST",
    body: formData,
    // headers: {'Content-Type': 'multipart/form-data'}
  };
  const url = `${config.server_url}/discount/create`;
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};

export const deleteDiscount = async () => {
  const option = fetchOptions("POST");
  const url = `${config.server_url}/discount/delete`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((res) => res.text())
      .then((data) => {
        // console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data));
        resolve("success");
      });
  });
};
