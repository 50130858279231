import "./home.css";
import Header from "../../../Components/Client/header/header";
import Landing from "./Landing-Section/landing";
import Trending from "./Trending-Section/trending";
import Browse from "./Browse-section/browse";
import Category from "./Category-section/category";
import Footer from "../../../Components/Client/footer/footer";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  // useEffect(()=>{
  //   getHome()
  // },[])

  return (
    <div className="home-main-container">
      <Header />
      <Landing />
      <Trending />
      <Browse />
      <Category />
      <Footer/>
    </div>
  );
};
export default Home;
