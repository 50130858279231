import "./discount.css"
import Header from "../../../Components/Client/header/header"
import Footer from "../../../Components/Client/footer/footer";
import DiscountSection1 from "./Discount-section-1/discount-section-1";
import { useSelector } from "react-redux"
import {useEffect} from 'react';

const Discount = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    const discounts = useSelector((state) => state.discount)
    return (
        <div className="about-main-container">
            <Header />
            {
                discounts === null ? <div>LOADING</div> : discounts.map((discount) => {
                    return (
                        <>
                            <DiscountSection1 name={discount.discountTitle} image={discount.discountImage} description={discount.discountDescription} />
                        </>
                    )
                })
            }
            <Footer />
        </div>
    )
}

export default Discount