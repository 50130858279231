import "./products.css";
import ProductCard from "../../../Components/Client/product-card/product-card";
import Header from "../../../Components/Client/header/header";
// import products from "./product.json";
// import productimg from "../../../Assets/Client/products/product1.jpg";
import Footer from "../../../Components/Client/footer/footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Products = () => {
  const products = useSelector((state) => state.products)
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [pathname]);


  const productCard = products.map((product) => (
    <ProductCard
      image={product.productImage}
      title={product.productName}
      description={product.productDescription}
      price={product.price}
    />
  ));
  return (
    <div className="product-main-container">
      <Header />
      <div className="product-main-warp">
        <div className="product-title-container">
          <div className="product-title">Products</div>
        </div>
        <div className="product-container">{productCard}</div>
      </div>
      <Footer />
    </div>
  );
};
export default Products;
