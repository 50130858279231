import { SET_CATEGORY, SET_SUB_CATEGORY } from "../../actions/category.action";

export const setCategory = (state = null, action) => {
    switch (action.type) {
      case SET_CATEGORY:
        return action.payload;
      default:
        return state;
    }
  };

  export const setSubCategory = (state = null, action) => {
    switch (action.type) {
      case SET_SUB_CATEGORY:
        return action.payload;
      default:
        return state;
    }
  };

