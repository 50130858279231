import "./footer.css";
// import logo from "../../../Assets/Client/logo.png"
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
// import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import { ClientSiteMap } from "../../../Routes/Client/siteMap";
import { useSelector } from "react-redux";
import { getProduct } from "../../../Services/netwokcall.service";


const Footer = () => {
  const contact = useSelector((state) => state.contact);
  const categories = useSelector((state) => state.category);
  const style = useSelector((state) => state.style);
  const navigate = useNavigate();

  return (
    <footer className="footer-main-container">
      <div className="footer-container-1">
        <div className="footer-container-1-title">
          Get connected with us on social media
        </div>
        <div className="footer-container-1-social-container">
          <div
            onClick={() => {
              window.open(style.facebook);
            }}
          >
            <FacebookIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
              fontSize="medium"
            />
          </div>
          <div
            onClick={() => {
              window.open(style.twitter);
            }}
          >
            <TwitterIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            />
          </div>
          <div
            onClick={() => {
              window.open(style.instagram);
            }}
          >
            <InstagramIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            />
          </div>
          <div
            onClick={() => {
              window.open("https://wa.me/" + style.whatsapp);
            }}
          >
            <WhatsAppIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            />
          </div>
          <div
            onClick={() => {
              window.open("tel:" + style.telephone);
            }}
          >
            <CallIcon
              style={{
                color: "white",
                width: 20,
                height: 20,
                cursor: "pointer",
              }}
            />
          </div>
          {/* <ShareIcon style={{ color: "white", width: 20, height: 20, cursor: "pointer" }} /> */}
        </div>
      </div>
      <div className="footer-container-2">
        <div className="footer-container-2-left">
          <div className="footer-container-2-left-warp">
            <div className="footer-container-2-left-title">
              <img
                className="footer-logo"
                src={style === null ? "loading" : style.logo}
                alt=""
              />
            </div>
            <div className="footer-container-2-left-content">
              {style === null ? "loading" : style.footerDescription}
            </div>
          </div>
        </div>
        <div className="footer-container-2-right">
          <div className="footer-container-2-right-warp">
            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Category</div>
              <div
                onClick={async() => {
                  await getProduct("", "")
                  navigate(ClientSiteMap.ProductsPage.path, { replace: true });
                }}
                className="footer-container-2-right-content"
              >
                All Products
              </div>
              {categories === null
                ? "loading.."
                : categories.slice(0, 4).map((category) => {
                    return (
                      <div
                        onClick={async() => {
                          await getProduct(category.categoryId, "")
                          navigate(ClientSiteMap.ProductsPage.path, {
                            replace: true,
                          });
                        }}
                        className="footer-container-2-right-content"
                      >
                        {category.categoryName}
                      </div>
                    );
                  })}
            </div>

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Company</div>
              <div
                onClick={() => {
                  navigate(ClientSiteMap.AboutPage.path, { replace: true });
                }}
                className="footer-container-2-right-content"
              >
                About us
              </div>
              <div
                onClick={() => {
                  navigate(ClientSiteMap.ContactPage.path, { replace: true });
                }}
                className="footer-container-2-right-content"
              >
                Contact us
              </div>
              <div
                onClick={() => {
                  navigate(ClientSiteMap.ProductsPage.path, { replace: true });
                }}
                className="footer-container-2-right-content"
              >
                Products
              </div>
              <div
                onClick={() => {
                  navigate(ClientSiteMap.DiscountPage.path, { replace: true });
                }}
                className="footer-container-2-right-content"
              >
                Rewards
              </div>
              <div
                onClick={() => {
                  navigate(ClientSiteMap.BranchPage.path, { replace: true });
                }}
                className="footer-container-2-right-content"
              >
                Store Locator
              </div>
            </div>

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Timing</div>
              <div className="footer-container-2-right-content">
                Weekdays:{" "}
                {style === null ? "10AM - 10PM" : style.footerWeekdays}
              </div>
              <div className="footer-container-2-right-content">
                Weekends: {style === null ? "9AM - 10PM" : style.footerWeekends}
              </div>
            </div>

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Address</div>
              <div className="footer-container-2-right-content">
                {contact === null ? "loading.." : contact.contactAddress}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-container-3">
        <h1 className="footer-container-3-content">
          Designed and developed by Brite Apps
        </h1>
      </div>
    </footer>
  );
};
export default Footer;
