// production config

// export const config = {
//   server_url: process.env.REACT_APP_PRODUCTION_SERVER_URL,
//   website_url:process.env.REACT_APP_PRODUCTION_WEBSITE_URL
// }

// development config


export const config = {
  server_url: "https://api.tnagarshobasilks.com",
  styleId: 1
};

// export const config = {
//   server_url: "http://43.205.217.105:5000",
//   styleId: 1
// };
