import "./discount-section-1.css"
// import browse from "../../../../Assets/Client/browse.png"
import playstore from "../../../../Assets/Client/playstore.png"
import appstore from "../../../../Assets/Client/appstore.png"
import { useSelector } from "react-redux";


const DiscountSection1 = ({name, image, description}) => {
  const style = useSelector((state) => state.style);
  return (
    <section className="discountsection1-main-container">
        <div className="discountsection1-left-container">
            <img className="discountsection1-image" src={image} alt="" />
        </div>
        <div className="discountsection1-right-container">
            <div className="discountsection1-right-title">{name}</div>
            <div className="discountsection1-right-content">{description}</div>
            <div className="discountsection1-right-content">We are also available in:</div>
            <div className="discountsection1-right-applink-container">
              <div onClick={()=>{ window.open(style.playstore)}} className="applink-container">
                <img className="applink" src={playstore} alt="" />
                <div className="discountsection1-right-content">
                playstore
                </div>
              </div>
              <div onClick={()=>{ window.open(style.appstore)}} className="applink-container">
                <img className="applink" src={appstore} alt="" />
                <div className="discountsection1-right-content">
                Appstore
                </div>
              </div>
            </div>
        </div>
   </section>
  )
}
export default DiscountSection1