import "./product-card.css";
import { useNavigate } from "react-router-dom";
import { ClientSiteMap } from "../../../Routes/Client/siteMap";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import ContactsIcon from '@mui/icons-material/Contacts';
import { useSelector } from "react-redux";

const ProductCard = ({ image, title, description, price }) => {
  const style = useSelector((state) => state.style);
  const navigate = useNavigate();
  const routeToContact = () => {
    navigate(ClientSiteMap.ContactPage.path, { replace: true });
  };
  return (
    <div className="productcard-main-container">
      <div className="productcard-image-container">
        <img className="product-image" src={image} alt="" />
      </div>
      <div className="productcard-content-container">
        <div className="productcard-content-left">
          <div className="productcard-content-title">{title}</div>
          <div className="productcard-content-description">{description}</div>
          {/* <div className="productcard-content-price">{price}</div> */}
        </div>
        <div className="productcard-content-right">
          <img src="" alt="" />
        </div>
      </div>
      <div className="productcard-button-container">
        <div
          className="product-card-button"
          onClick={() => {
            routeToContact();
          }}
        >
          {
            window.screen.width < 420 ? <ContactsIcon /> : "Contact Now"
          }
        </div>
        <div className="productcard-icon-container">
          <div onClick={() => {
            window.open("https://wa.me/" + style.whatsapp);
          }}>
            <WhatsAppIcon style={{ color: "var(--template-color)", cursor: "pointer" }} />
          </div>
          <div onClick={() => {
            window.open("tel:" + style.telephone);
          }}>
            <CallIcon style={{ color: "var(--template-color)", cursor: "pointer" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductCard;
