import './App.css';
import Routes from './Routes';
import { useEffect, useState } from 'react';
// import { setMenu } from './Redux/actions/menuItems.action';
import { getTemplateStyle, getCategory, getHome, getProduct, getAbout, getContact, getBranch, getDiscount } from './Services/netwokcall.service';
import {menu} from "./Components/Client/Custom-Menu-Dropdown/generateMenu"
// import {resultJson} from "./Components/Client/Custom-Menu-Dropdown/dropdownAPI"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function App() {
  const [mounted, setMounted] = useState(false)
  // const [category, setCategory] = useState({})
  useEffect(()=>{
    (async()=>{
      await getTemplateStyle()
      await getHome()
      await getProduct()
      await getAbout()
      await getContact()
      await getBranch()
      await getDiscount()
      await getCategory()
      await menu()
      setMounted(true)
    })();
    // menu(resultJson, category)
  },[])


  return (
      mounted  ? 
      <Routes/> : 
      <div className='main-page'>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
        Please Wait, Site is loading
      </div>
  );
}

export default App;
