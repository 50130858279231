import "./admin-contact-page.css";
import Sidebar from "../../../Components/Admin/sidebar/sidebar";
import { useFormik } from "formik";
// import * as Yup from "yup";
import {
  createContact,
  deleteContact,
} from "../../../Services/networkcall.Admin.service";
// import { useSelector } from "react-redux";
// import { getContact } from "../../../Services/netwokcall.service";
// import { useEffect, useState } from "react";

const AdminContact = () => {
  // const category = useSelector((state) => state.category);
  // const subcategory = useSelector((state) => state.subcategory);

  const contactForm = useFormik({
    initialValues: {
      contactDescription: "",
      location: "",
      contactAddress: "",
      contactPhone: "",
      contactEmail: "",
      contactImage: "",
    },
    // validationSchema: Yup.object({
    //   contactDescription: Yup.string().max(25, "Must be 15 characters or less"),
    //   location: Yup.string().max(25, "Must be 15 characters or less"),
    //   contactAddress: Yup.string().max(25, "Must be 15 characters or less"),
    //   contactPhone: Yup.string().max(25, "Must be 15 characters or less"),
    //   contactEmail: Yup.string().max(25, "Must be 15 characters or less"),
    //   contactImage: Yup.string().max(25, "Must be 15 characters or less"),
    // }),
    onSubmit: async (values) => {
      // console.log(JSON.stringify(values, null, 2));
      alert(JSON.stringify(values, null, 2));
      await createContact(
        values.contactDescription,
        values.location,
        values.contactAddress,
        values.contactPhone,
        values.contactEmail,
        values.contactImage
      );
      alert("contact created or updated successfully")
    },
  });

  // useEffect(() => {
  //   getContact();
  // }, []);

  return (
    <div className="admin-contact-main-container">
      <Sidebar />
      <div className="admin-contact-container">
        <form className="form-container" onSubmit={contactForm.handleSubmit}>
          <div className="contact-form-title">Modify Contact Us</div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="contactDescription">
              contactDescription
            </label>
            <input
              className="form-input"
              id="contactDescription"
              name="contactDescription"
              type="text"
              onChange={contactForm.handleChange}
              onBlur={contactForm.handleBlur}
              value={contactForm.values.contactDescription}
            />
            {contactForm.touched.contactDescription &&
            contactForm.errors.contactDescription ? (
              <div className="contact-error">
                {contactForm.errors.contactDescription}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="location">
              location
            </label>
            <input
              className="form-input"
              id="location"
              name="location"
              type="text"
              onChange={contactForm.handleChange}
              onBlur={contactForm.handleBlur}
              value={contactForm.values.location}
            />
            {contactForm.touched.location && contactForm.errors.location ? (
              <div className="contact-error">{contactForm.errors.location}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="contactAddress">
              contactAddress
            </label>
            <input
              className="form-input"
              id="contactAddress"
              name="contactAddress"
              type="text"
              onChange={contactForm.handleChange}
              onBlur={contactForm.handleBlur}
              value={contactForm.values.contactAddress}
            />
            {contactForm.touched.contactAddress &&
            contactForm.errors.contactAddress ? (
              <div className="contact-error">
                {contactForm.errors.contactAddress}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="contactPhone">
              contactPhone
            </label>
            <input
              className="form-input"
              id="contactPhone"
              name="contactPhone"
              type="text"
              onChange={contactForm.handleChange}
              onBlur={contactForm.handleBlur}
              value={contactForm.values.contactPhone}
            />
            {contactForm.touched.contactPhone &&
            contactForm.errors.contactPhone ? (
              <div className="contact-error">
                {contactForm.errors.contactPhone}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="contactEmail">
              contactEmail
            </label>
            <input
              className="form-input"
              id="contactEmail"
              name="contactEmail"
              type="text"
              onChange={contactForm.handleChange}
              onBlur={contactForm.handleBlur}
              value={contactForm.values.contactEmail}
            />
            {contactForm.touched.contactEmail &&
            contactForm.errors.contactEmail ? (
              <div className="contact-error">
                {contactForm.errors.contactEmail}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="contactImage">
              contactImage
            </label>
            <input
              className="form-input"
              id="contactImage"
              name="contactImage"
              type="file"
              onChange={(event) => {
                // console.log("image", event.currentTarget.files[0]);
                contactForm.setFieldValue(
                  "contactImage",
                  event.currentTarget.files[0]
                );
              }}
            />
            {contactForm.touched.contactImage &&
            contactForm.errors.contactImage ? (
              <div className="contact-error">
                {contactForm.errors.contactImage}
              </div>
            ) : null}
          </div>

              <div>
                Note: You can create and update informations using same button
              </div>
          <button className="contact-submit" type="submit">
            Create Or Update
          </button>
          <button
            className="contact-submit"
            onClick={async () => await deleteContact()}
          >
            Delete
          </button>
        </form>
      </div>
    </div>
  );
};
export default AdminContact;
