import "./productBox.css";
// import { useNavigate } from "react-router-dom";
// import { ClientSiteMap } from "../../../Routes/Client/siteMap";
import { deleteProduct } from "../../../Services/networkcall.Admin.service";
import { getProduct } from "../../../Services/netwokcall.service";


const ProductCard = ({ id, image, title, description, price }) => {
  // const navigate = useNavigate();
  const routeToDelete = async() => {
    alert(`you are trying to delete ${title}`)
    await deleteProduct(id)
    await getProduct()
    alert(`${title} deleted succfully`)
  };
  return (
    <div className="productcard-main-container">
      <div className="productcard-image-container">
        <img className="product-image" src={image} alt="" />
      </div>
      <div className="productcard-content-container">
        <div className="productcard-content-left">
          <div className="productcard-content-title">{title}</div>
          <div className="productcard-content-description">{description}</div>
          {/* <div className="productcard-content-price">{price}</div> */}
        </div>
        <div className="productcard-content-right">
          <img src="" alt="" />
        </div>
      </div>
      <div className="productcard-button-container">
        <div
          className="product-card-button"
          onClick={() => {
            routeToDelete();
          }}
        >
          DELETE
        </div>
      </div>
    </div>
  );
};
export default ProductCard;
