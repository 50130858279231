export const SET_PRODUCT = "SET_PRODUCT"


export const setProduct = (payload) => (dispatch) => {
  dispatch({
    type: SET_PRODUCT,
    payload: payload,
  });
};

