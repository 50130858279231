
import { SET_STYLE } from "../../actions/style.action";

export const setStyle = (state = null, action) => {
    switch (action.type) {
      case SET_STYLE:
        return action.payload;
      default:
        return state;
    }
  };

