import { SET_CONTACT } from "../../actions/contact.action";

export const setContact = (state = null, action) => {
    switch (action.type) {
      case SET_CONTACT:
        return action.payload;
      default:
        return state;
    }
  };

