export const ClientSiteMap = {
    HomePage: {
      title: "Client home page",
      path: "/",
      description: "template client home page",
    },
    ProductsPage: {
      title: "Client product page",
      path: "/products",
      description: "template client products page",
    },
    AboutPage: {
      title: "Client about page",
      path: "/about",
      description: "template client about page",
    },
    ContactPage: {
      title: "Client contact page",
      path: "/contact",
      description: "template client contact page",
    },
    BranchPage: {
      title: "Client branch page",
      path: "/store-locator",
      description: "template client branch page",
    },
    DiscountPage: {
      title: "Client discount page",
      path: "/discount",
      description: "template client discount page",
    }
  };