import "./dropdown.css";
import "antd/dist/antd.min.css";
import { useEffect, useState } from "react";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ClientSiteMap } from "../../../Routes/Client/siteMap";
import {resultJson} from "./dropdownAPI";
import { getProduct } from "../../../Services/netwokcall.service";
// import {menu} from "./generateMenu"
// import { useEffect, useState } from "react";

const Dropdown = () => {
  const [menuItems, setMenuItems] = useState(resultJson)
  // const menu = useSelector((state)=> state.menu)
  const category = useSelector((state) => state.category)
  useEffect(()=>{
      // console.log("session", JSON.parse(sessionStorage.getItem("menu")));
      setMenuItems(JSON.parse(sessionStorage.getItem("menu")))
  },[])

  const navigate = useNavigate();
  const [current, setCurrent] = useState("mail");

  const onClick = (e) => {
    // console.log("click ", e);
    setCurrent(e.key);
    routingFunction(e.key);
  };

  const routingFunction = (key) => {
    // console.log("type",key.split("/")[0]);
    // make it as if condition once network call added
    if(key === "home"){
      navigate(ClientSiteMap.HomePage.path, { replace: true });
    }
    if(key === "products"){
      getProduct("", "")
      navigate(ClientSiteMap.ProductsPage.path, { replace: true });
    }
    if(key.split("/")[0] === "category"){
      // console.log("type", key.split("/")[1]);
      getProduct(key.split("/")[1], "")
      navigate(ClientSiteMap.ProductsPage.path, { replace: true });
    }
    if(key.split("/")[0] === "subcategory"){
      // console.log("type", key.split("/")[1]);
      getProduct("", key.split("/")[1])
      navigate(ClientSiteMap.ProductsPage.path, { replace: true });
    }
    if(key === "discount"){
      navigate(ClientSiteMap.DiscountPage.path, { replace: true });
    }
    if(key === "contact"){
      navigate(ClientSiteMap.ContactPage.path, { replace: true });
    }
    // switch (key) {
    //   case "home":
    //     break;
    //   case "products":
    //     break;
    //   case "about":
    //     navigate(ClientSiteMap.AboutPage.path, { replace: true });
    //     break;
    //   case "contact":
    //     navigate(ClientSiteMap.ContactPage.path, { replace: true });
    //     break;
    //   case "branch":
    //     navigate(ClientSiteMap.BranchPage.path, { replace: true });
    //     break;
    //   case "discount":
    //     break;
    //   default:
    //     break;
    // }
  };


  return (
      category === null ? <div>LOADING</div> : 
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={menuItems}
      Style={{ Width: "80" }}
      // openKeys={["products", "contact"]}
    />
  );
};
export default Dropdown;
