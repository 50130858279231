import { SET_PRODUCT } from "../../actions/product.action";

export const setProduct = (state = null, action) => {
    switch (action.type) {
      case SET_PRODUCT:
        return action.payload;
      default:
        return state;
    }
  };


