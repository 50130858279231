import { SET_MENU } from "../../actions/menuItems.action";

export const setMenu = (state = null, action) => {
    switch (action.type) {
      case SET_MENU:
        return action.payload;
      default:
        return state;
    }
  };

