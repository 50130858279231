
// import { store } from '../../..';
// import { setMenu } from '../../../Redux/actions/menuItems.action';
import { getCategory } from '../../../Services/netwokcall.service';
// import { store } from '../../..';
import {resultJson} from "./dropdownAPI"
// const util = require('util')

// const category = store.getState(category)
export const menu = async() => {
  const category =  await getCategory()
  // console.log("from menu function", category.length);
  return new Promise((resolve, reject) => {
    for (let cat = 0;cat<category.length;cat++){
            resultJson[1].children.push({
                "label":category[cat].categoryName,
                "key":`category/${category[cat].categoryId}`,
                "children":[]
            });
            
            // // console.log(resultJson[1].children);
            // console.log("subcategory",category[cat]);
            for (let subcat = 0;subcat<category[cat].subcategory.length;subcat++){
              // console.log("from menu", resultJson[1].children[cat]);
              resultJson[1].children[cat].children.push({
                "label": category[cat].subcategory[subcat].name,
                "key": `subcategory/${category[cat].subcategory[subcat].subcategoryId}`
              });
            }
                resultJson[1].children[cat].children.splice(0, 0,{
                  "label": "All",
                  "key": `category/${category[cat].categoryId}`
                }
                )
              }
              resultJson[1].children.splice(0, 0,    {
                "label": "All Products",
                "key": "products"
              }
              )
              
              // console.log(resultJson, false, null, true);
              // setMenu(resultJson)
              sessionStorage.setItem("menu", JSON.stringify(resultJson))
              resolve(resultJson, false, null, true /* enable colors */)
            })
            }