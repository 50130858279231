import "./contact.css"

import Header from "../../../Components/Client/header/header"
import ContactSection1 from "./Contact-section-1/contact-section-1"
import Map from "./Map-section/map"
import ContactDetails from "./Contact-details-section/contact-details"
import Footer from "../../../Components/Client/footer/footer";
import { useSelector } from "react-redux"
import {useEffect} from 'react';

const Contact = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const contact = useSelector((state) => state.contact)
  return (
    <div className="contact-main-container">
      <Header />
      {
        contact === null ? <div>LOADING</div> :
          <>
            <ContactSection1 image={contact.contactImage} description={contact.contactDescription}/>
            <Map link={contact.location}/>
            <ContactDetails address={contact.contactAddress} phone={contact.contactPhone} email={contact.contactEmail} />
          </>
      }
      <Footer />
    </div>
  )
}
export default Contact