import "./carosel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";

const CustomCarosel = () => {
  const home = useSelector((state) => state.home)

  return (
    <Carousel emulateTouch={false} showThumbs={false} autoPlay={true} infiniteLoop={true} dynamicHeight={true} showStatus={false}>
      {
        home === null ? <div>Loading</div> : home.homeCarousel.map((image) => {
          return (
            <div className="carousel-image-controller">
              <img src={image} className="carosel-image" alt="" />
            </div>
          )
        })
      }
    </Carousel>
  );
};
export default CustomCarosel;
