import { SET_BRANCH } from "../../actions/branch.action";

export const setBranch = (state = null, action) => {
    switch (action.type) {
      case SET_BRANCH:
        return action.payload;
      default:
        return state;
    }
  };

