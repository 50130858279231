import "./trending.css"
import ProductCard from "../../../../Components/Client/product-card/product-card"
// import productimg from "../../../../Assets/Client/product.png"
// import products from "./product.json"
import { useNavigate } from "react-router-dom";
import { ClientSiteMap } from "../../../../Routes/Client/siteMap";
import { useSelector } from "react-redux";

const Trending = () => {
  const home = useSelector((state) => state.home)
  const navigate = useNavigate();
  const routeToProduct = () => {
    navigate(ClientSiteMap.ProductsPage.path, { replace: true });
  }
  const productCard = 
    home === null ? 
    <div>LOADING</div>:
     home.trending.map((product)=><ProductCard image={product.productImage} title={product.productName} description={product.productDescription} price={product.price}/>)
  
  return (
    <section className="trending-main">
      <div className="trending-title-container">
        <div className="trending-heading">Trending</div>
        <div className="trending-view-more" onClick={()=>{routeToProduct()}}>View All</div>
      </div>
    <div className="trending-main-container">
        {productCard}
    </div>
    </section>
  )
}
export default Trending