import { config } from "../Config/server.config";
import { store } from "../index";

// imports from redux - actions
import { setCategory, setSubCategory } from "../Redux/actions/category.action";
import { setProduct } from "../Redux/actions/product.action";
import { setStyle } from "../Redux/actions/style.action";
import { setHome } from "../Redux/actions/home.action";
import { setAbout } from "../Redux/actions/about.action";
import { setContact } from "../Redux/actions/contact.action";
import { setBranch } from "../Redux/actions/branch.action";
import { setDiscount } from "../Redux/actions/discount.action";
// network call default options
const fetchOptions = (method, raw = null, referCode = null) => {
  // network call default header
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // TODO write conditions for get method(get method does not have body)
  if (method === "GET") {
    const requestOptions = {
      method: method,
      // headers: myHeaders,
      redirect: "follow"
    };
    return requestOptions;
  } else {
    const requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    return requestOptions;
  }
};

// To get the all the details about the specific user, call this function

export const getTemplateStyle = async () => {
    // console.log("style_id", config.styleId);
  var raw = JSON.stringify({
    styleId: config.styleId
  });
  const option = fetchOptions("POST", raw);
  const url = `${config.server_url}/style/get`;
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((res) => res.text())
      .then((data) => {
        console.log("from network", JSON.parse(data));
        const json_data = JSON.parse(data);
        store.dispatch(setStyle(json_data.result[0]));
        resolve("success");
      });
  });
};

// To get the all category, call this function

export const getCategory = async () => {
  // // console.log("style_id", config.styleId);
var raw = JSON.stringify({
  // styleId: config.styleId
});
const option = fetchOptions("POST", raw);
const url = `${config.server_url}/category/readCategory`;
return new Promise((resolve, reject) => {
  fetch(url, option)
    .then((res) => res.text())
    .then((data) => {
      console.log("from network", JSON.parse(data));
      const json_data = JSON.parse(data);
      store.dispatch(setCategory(json_data.result));
      resolve(json_data.result);
    });
});
};

// To get the all subcategory, call this function

export const getSubCategory = async (categoryId) => {
  // // console.log("style_id", config.styleId);
var raw = JSON.stringify({
  "categoryId": categoryId
});
const option = fetchOptions("POST", raw);
const url = `${config.server_url}/category/readSubCategory`;
return new Promise((resolve, reject) => {
  fetch(url, option)
    .then((res) => res.text())
    .then((data) => {
      console.log("from network", JSON.parse(data));
      const json_data = JSON.parse(data);
      store.dispatch(setSubCategory(json_data.result[0].subcategory));
      resolve("success");
    });
});
};

// To get the all product, call this function

export const getProduct = async (categoryId, subcategoryId) => {
  // // console.log("style_id", config.styleId);
var raw = JSON.stringify({
  "productCategoryId": categoryId,
  "productSubcategoryId" : subcategoryId
});
const option = fetchOptions("POST", raw);
const url = `${config.server_url}/product/read`;
return new Promise((resolve, reject) => {
  fetch(url, option)
    .then((res) => res.text())
    .then((data) => {
      console.log("from network", JSON.parse(data));
      const json_data = JSON.parse(data);
      store.dispatch(setProduct(json_data.result));
      resolve("success");
    });
});
};

// To get the home content, call this function

export const getHome = async () => {
  // // console.log("style_id", config.styleId);
var raw = JSON.stringify({
  // "categoryId": categoryId
});
const option = fetchOptions("POST", raw);
const url = `${config.server_url}/home/read`;
return new Promise((resolve, reject) => {
  fetch(url, option)
    .then((res) => res.text())
    .then((data) => {
      // console.log("from network", JSON.parse(data));
      const json_data = JSON.parse(data);
      store.dispatch(setHome(json_data.result));
      resolve("success");
    });
});
};

// To get the about content, call this function

export const getAbout = async () => {
  // // console.log("style_id", config.styleId);
var raw = JSON.stringify({
  // "categoryId": categoryId
});
const option = fetchOptions("POST", raw);
const url = `${config.server_url}/about/read`;
return new Promise((resolve, reject) => {
  fetch(url, option)
    .then((res) => res.text())
    .then((data) => {
      // console.log("from network", JSON.parse(data));
      const json_data = JSON.parse(data);
      store.dispatch(setAbout(json_data.result[0].aboutArray));
      resolve("success");
    });
});
};

// To get the contact content, call this function

export const getContact = async () => {
var raw = JSON.stringify({
});
const option = fetchOptions("POST", raw);
const url = `${config.server_url}/contact/read`;
return new Promise((resolve, reject) => {
  fetch(url, option)
    .then((res) => res.text())
    .then((data) => {
      // console.log("from network", JSON.parse(data));
      const json_data = JSON.parse(data);
      store.dispatch(setContact(json_data.result[0]));
      resolve("success");
    });
});
};

// To get the branch content, call this function

export const getBranch = async () => {
  // // console.log("style_id", config.styleId);
var raw = JSON.stringify({
  // "categoryId": categoryId
});
const option = fetchOptions("POST", raw);
const url = `${config.server_url}/branch/read`;
return new Promise((resolve, reject) => {
  fetch(url, option)
    .then((res) => res.text())
    .then((data) => {
      // console.log("from network", JSON.parse(data));
      const json_data = JSON.parse(data);
      store.dispatch(setBranch(json_data.result[0].branchArray));
      resolve("success");
    });
});
};

// To get the discount content, call this function

export const getDiscount = async () => {
  // // console.log("style_id", config.styleId);
var raw = JSON.stringify({
  // "categoryId": categoryId
});
const option = fetchOptions("POST", raw);
const url = `${config.server_url}/discount/read`;
return new Promise((resolve, reject) => {
  fetch(url, option)
    .then((res) => res.text())
    .then((data) => {
      // console.log("from network", JSON.parse(data));
      const json_data = JSON.parse(data);
      store.dispatch(setDiscount(json_data.result[0].discountArray));
      resolve("success");
    });
});
};