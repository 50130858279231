import "./category.css"
import CategoryCard from "../../../../Components/Client/category-card/category-card"
// import categoryimg from "../../../../Assets/Client/category.png"
// import categories from "./categoryAPI.json"
import { useSelector } from "react-redux";

const Category = () => {
  const categories = useSelector((state) => state.category)
  const categoryCard = categories.slice(0,3).map((category)=><CategoryCard id={category.categoryId} image={category.categoryImage} title={category.categoryName} description={category.categoryDescription}/>)
  return (
    <section className="category-main">
      <div className="category-title-container">
        <div className="category-heading">Category</div>
        {/* <div className="category-view-more">View All</div> */}
      </div>
    <div className="category-main-container">
        {categoryCard}
    </div>
    </section>
  )
}
export default Category