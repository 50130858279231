import { BrowserRouter } from "react-router-dom";

import AdminRoutes from "./Admin/routes";
import ClientRoutes from "./Client/routes";

const Routes = () => {
  return (
    <BrowserRouter>
      <ClientRoutes />
      <AdminRoutes />
    </BrowserRouter>
  );
};

export default Routes