import "./branch.css"
import Header from "../../../Components/Client/header/header";
import Footer from "../../../Components/Client/footer/footer";
import { useState } from "react";
import { useSelector } from "react-redux"
import {useEffect} from 'react';

const Branch = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    const branch = useSelector((state) => state.branch)
    const [location, setLocation] = useState(branch[0].branchLocation)
    function handleClickOpen(value) {
        setLocation(value)
    }

    return (
        <div className="branch-main-container">
            <Header />
            <div className="branch-main-warp">
                <div className="branch-main-warp-left-container">
                    <div className="branch-left-warp">
                        <div className="branch-title">
                            Store Locator
                        </div>
                        {
                            branch === null ? <div>LOADING</div> : branch.map((brn) => {
                                return (
                                    <div className="location-container">
                                        <div className="location-left-container">
                                            <div className="branch-place">{brn.branchTitle}</div>
                                            <div className="branch-address">
                                                {brn.branchDescription}
                                            </div>
                                        </div>
                                        <div className="location-right-container">
                                            <div
                                                className="browse-right-button"
                                                onClick={() => {
                                                    handleClickOpen(brn.branchLocation);
                                                }}
                                            >
                                                Go
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>
                <div className="branch-main-warp-right-container">
                    <iframe className="map" title="address" src={location} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Branch