import "./admin-product-read.css"
import Sidebar from "../../../Components/Admin/sidebar/sidebar"
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { useEffect } from "react";
// import { getProduct } from "../../../Services/netwokcall.service";
// import ProductCard from "../../../Components/Client/product-card/product-card";
import ProductCard from "../../../Components/Admin/productBox/productBox";
import { useSelector } from "react-redux";

const AdminProductRead = () => {
    const products = useSelector((state) => state.products)
    // useEffect(() => {
    //     getProduct()
    //     // getSubCategory()
    // }, [])


    return (
        <div className="admin-product-read-main-container">
            <Sidebar />
            <div className="admin-product-read-container">
                {
                    (products === null || products.length === 0) ? ()=>{return <div>No products found</div>} : products.map((product) => {

                        // console.log(product);
                        return(
                            <ProductCard
                            id={product.productId}
                            image={product.productImage}
                            title={product.productName}
                            description={product.productDescription}
                            price={product.price}
                            />
                            )
                    })
                }

            </div>
        </div>
    )
}
export default AdminProductRead
