import "./contact-details.css";
import Divider from "@mui/material/Divider";
import { useFormik } from "formik";
import * as Yup from "yup";

const ContactDetails = ({address, phone, email}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      phone: Yup.number().min(10, "Must be 10 characters").required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <section className="contact-details-main-container">
      <div className="contact-details-main-left">
        <div className="contact-details-main-left-warp">
          <div className="contact-details-title">Contact Details</div>
          <div className="contact-details-content">
            Address : {address}
          </div>
          <div className="contact-details-content">Phone : {phone}</div>
          <div className="contact-details-content">Email : {email}</div>
        </div>
      </div>
      <Divider orientation="vertical" variant="middle" flexItem />
      <div className="contact-details-main-right">
        <form className="form-container" onSubmit={formik.handleSubmit}>
          <div className="contact-form-title">Leave us a message</div>
          <div className="contact-form-input-warp">
            <label className="label" htmlFor="name">
              Name
            </label>
            <input
              className="form-input"
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="contact-error">{formik.errors.name}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="phone">
              Phone
            </label>
            <input
              className="form-input"
              id="phone"
              name="phone"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="contact-error">{formik.errors.phone}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="email">
              Email Address
            </label>
            <input
              className="form-input"
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="contact-error">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="message">
              Message
            </label>
            <textarea
              className="form-text"
              id="message"
              name="message"
              type="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            />
            {formik.touched.message && formik.errors.message ? (
              <div className="contact-error">{formik.errors.message}</div>
            ) : null}
          </div>

          <button className="contact-submit" type="submit">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};
export default ContactDetails;
