import { SET_HOME } from "../../actions/home.action";

export const setHome = (state = null, action) => {
    switch (action.type) {
      case SET_HOME:
        return action.payload;
      default:
        return state;
    }
  };

