import "./admin-home-update-page.css";
import Sidebar from "../../../Components/Admin/sidebar/sidebar";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
// import { useEffect, useState } from "react";
import { deleteHome } from "./../../../Services/networkcall.Admin.service";
import {
  changeHomeContent,
  changeHomeImages,
  createUpdateHome,
} from "../../../Services/networkcall.Admin.service";
// import { InputLabel } from "@mui/material";

const AdminHomeUpdate = () => {
  const products = useSelector((state) => state.products);

  const homeForm = useFormik({
    initialValues: {
      homeImage: "",
      homeCarouselImages: [],
      homeTrendingFirst: products[0].productId,
      homeTrendingSecond: products[0].productId,
      homeTrendingThird: products[0].productId,
      homeVideoUrl: "",
      homeVideoTitle: "",
    },
    onSubmit: async (values) => {
      // console.log(JSON.stringify(values, null, 2));
      alert(JSON.stringify(values, null, 2));
      await createUpdateHome(
        values.homeCarouselImages,
        values.homeImage,
        values.homeTrendingFirst,
        values.homeTrendingSecond,
        values.homeTrendingThird,
        values.homeVideoTitle,
        values.homeVideoUrl
      );
      alert("home created successfully")
    },
  });

  const updateHomeContent = async () => {
    const homeTrending = [
      homeForm.values.homeTrendingFirst,
      homeForm.values.homeTrendingSecond,
      homeForm.values.homeTrendingThird,
    ];
    console.log("trending", homeTrending);
    alert(
      `${homeTrending}, ${homeForm.values.homeVideoTitle}, ${homeForm.values.homeVideoUrl}`
    );
    await changeHomeContent(
      homeTrending,
      homeForm.values.homeVideoTitle,
      homeForm.values.homeVideoUrl
    );
    alert("home content updated successfully");
  };

  const updateHomeImage = async () => {
    alert(
      `${homeForm.values.homeCarouselImages}, ${homeForm.values.homeImage}`
    );
    await changeHomeImages(
      homeForm.values.homeCarouselImages,
      homeForm.values.homeImage
    );
    alert("home images updated successfully");
  };
  
  return (
    <div className="admin-contact-main-container">
      <Sidebar />
      <div className="admin-contact-container">
        <form className="form-container" onSubmit={homeForm.handleSubmit}>
          <div className="contact-form-title">Modify Home</div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="homeVideoTitle">
              homeVideoTitle
            </label>
            <input
              className="form-input"
              id="homeVideoTitle"
              name="homeVideoTitle"
              type="text"
              onChange={homeForm.handleChange}
              onBlur={homeForm.handleBlur}
              value={homeForm.values.homeVideoTitle}
            />
            {homeForm.touched.homeVideoTitle &&
            homeForm.errors.homeVideoTitle ? (
              <div className="contact-error">
                {homeForm.errors.homeVideoTitle}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="homeVideoUrl">
              homeVideoUrl
            </label>
            <input
              className="form-input"
              id="homeVideoUrl"
              name="homeVideoUrl"
              type="text"
              onChange={(st) => {
                let value = st.target.value;
                let finalValue = "";
                let suffix = "https://www.youtube.com/embed/";
                if (value.includes(suffix)) {
                  if (value.split("/")[4] === "") {
                    finalValue = "";
                  } else {
                    finalValue = value;
                  }
                } else {
                  finalValue = suffix + value;
                }
                homeForm.setFieldValue("homeVideoUrl", finalValue);
              }}
              onBlur={homeForm.handleBlur}
              value={homeForm.values.homeVideoUrl}
            />
            {homeForm.touched.homeVideoUrl && homeForm.errors.homeVideoUrl ? (
              <div className="contact-error">
                {homeForm.errors.homeVideoUrl}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="homeImage">
              homeImage
            </label>
            <input
              className="form-input"
              id="homeImage"
              name="homeImage"
              type="file"
              onChange={(event) => {
                // console.log("image", event.currentTarget.files[0]);
                homeForm.setFieldValue(
                  "homeImage",
                  event.currentTarget.files[0]
                );
              }}
            />
            {homeForm.touched.homeImage && homeForm.errors.homeImage ? (
              <div className="contact-error">{homeForm.errors.homeImage}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="homeTrendingFirst">
              homeTrendingFirst
            </label>
            {/* <input
              className="form-input"
              id="homeTrendingFirst"
              name="homeTrendingFirst"
              type="text"
              onChange={homeForm.handleChange}
              onBlur={homeForm.handleBlur}
              value={homeForm.values.homeTrendingFirst}
            /> */}
            <select
              className="form-input"
              id="homeTrendingFirst"
              name="homeTrendingFirst"
              // style={{ display:"block"}}
              onChange={homeForm.handleChange}
              onBlur={homeForm.handleBlur}
            >
              {products === null ? (
                <option>No products Found</option>
              ) : (
                products.map((product) => (
                  <option key={product.productId} value={product.productId}>
                    {product.productName}
                  </option>
                ))
              )}
            </select>
            {homeForm.touched.homeTrendingFirst &&
            homeForm.errors.homeTrendingFirst ? (
              <div className="contact-error">
                {homeForm.errors.homeTrendingFirst}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="homeTrendingSecond">
              homeTrendingSecond
            </label>
            {/* <input
              className="form-input"
              id="homeTrendingSecond"
              name="homeTrendingSecond"
              type="text"
              onChange={homeForm.handleChange}
              onBlur={homeForm.handleBlur}
              value={homeForm.values.homeTrendingSecond}
            /> */}
            <select
              className="form-input"
              id="homeTrendingSecond"
              name="homeTrendingSecond"
              // style={{ display:"block"}}
              onChange={homeForm.handleChange}
              onBlur={homeForm.handleBlur}
            >
              {products === null ? (
                <option>No products Found</option>
              ) : (
                products.map((product) => (
                  <option key={product.productId} value={product.productId}>
                    {product.productName}
                  </option>
                ))
              )}
            </select>
            {homeForm.touched.homeTrendingSecond &&
            homeForm.errors.homeTrendingSecond ? (
              <div className="contact-error">
                {homeForm.errors.homeTrendingSecond}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="homeTrendingThird">
              homeTrendingThird
            </label>
            {/* <input
              className="form-input"
              id="homeTrendingThird"
              name="homeTrendingThird"
              type="text"
              onChange={homeForm.handleChange}
              onBlur={homeForm.handleBlur}
              value={homeForm.values.homeTrendingThird}
            /> */}
            <select
              className="form-input"
              id="homeTrendingThird"
              name="homeTrendingThird"
              displayEmpty
              // style={{ display:"block"}}
              onChange={homeForm.handleChange}
              onBlur={homeForm.handleBlur}
            >
              {products === null ? (
                <option>No products Found</option>
              ) : (
                products.map((product) => (
                  <option key={product.productId} value={product.productId}>
                    {product.productName}
                  </option>
                ))
              )}
            </select>
            {homeForm.touched.homeTrendingThird &&
            homeForm.errors.homeTrendingThird ? (
              <div className="contact-error">
                {homeForm.errors.homeTrendingThird}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="homeCarouselImage">
              homeCarouselImage
            </label>
            <input
              className="form-input"
              id="homeCarouselImage"
              name="homeCarouselImage"
              type="file"
              multiple
              onChange={(event) => {
                if (event.currentTarget.files.length === 5) {
                  homeForm.values.homeCarouselImages.push(
                    event.currentTarget.files
                  );
                } else {
                  alert("pick exactly 5 images");
                }
                // homeForm.setFieldValue(
                //   "homeImage",
                //   event.currentTarget.files[0]
                // );
              }}
            />
            {homeForm.touched.homeCarouselImages &&
            homeForm.errors.homeCarouselImages ? (
              <div className="contact-error">
                {homeForm.errors.homeCarouselImages}
              </div>
            ) : null}
          </div>
          <div className="home-buttons-container">
            <button className="contact-submit" type="submit">
              Create
            </button>
            <div className="contact-submit" onClick={() => updateHomeContent()}>
              Change Content
            </div>
            <div className="contact-submit" onClick={() => updateHomeImage()}>
              Change Images
            </div>
            <div
              className="contact-submit"
              onClick={async () => await deleteHome()}
            >
              Delete
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminHomeUpdate;
