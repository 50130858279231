import "./admin-branch-page.css";
import Sidebar from "../../../Components/Admin/sidebar/sidebar";
import { useFormik } from "formik";
import {
  createBranch,
  deleteBranch,
} from "../../../Services/networkcall.Admin.service";

const AdminBranch = () => {
  const branchForm = useFormik({
    initialValues: {
      index:"",
      branchTitle: "",
      branchDescription: "",
      branchLocation: "",
      branchImage: "",
    },
    onSubmit: async (values) => {
      // console.log(JSON.stringify(values, null, 2));
      alert(JSON.stringify(values, null, 2));
      await createBranch(
        values.index,
        values.branchTitle,
        values.branchDescription,
        values.branchImage,
        values.branchLocation
      );
      if(values.index === ""){
        alert("branch section created successfully")
      }
      else{
        alert("branch updated successfully")
      }
    },
  });
  return (
    <div className="admin-branch-main-container">
      <Sidebar />
      <div className="admin-branch-container">
        <form className="form-container" onSubmit={branchForm.handleSubmit}>
          <div className="contact-form-title">Modify Branch</div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="index">
              section
            </label>
            <input
              className="form-input"
              id="index"
              name="index"
              type="text"
              onChange={branchForm.handleChange}
              onBlur={branchForm.handleBlur}
              value={branchForm.values.index}
            />
            {branchForm.touched.index && branchForm.errors.index ? (
              <div className="contact-error">
                {branchForm.errors.index}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="branchTitle">
              branchTitle
            </label>
            <input
              className="form-input"
              id="branchTitle"
              name="branchTitle"
              type="text"
              onChange={branchForm.handleChange}
              onBlur={branchForm.handleBlur}
              value={branchForm.values.branchTitle}
            />
            {branchForm.touched.branchTitle && branchForm.errors.branchTitle ? (
              <div className="contact-error">
                {branchForm.errors.branchTitle}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="branchDescription">
              branchDescription
            </label>
            <input
              className="form-input"
              id="branchDescription"
              name="branchDescription"
              type="text"
              onChange={branchForm.handleChange}
              onBlur={branchForm.handleBlur}
              value={branchForm.values.branchDescription}
            />
            {branchForm.touched.branchDescription &&
            branchForm.errors.branchDescription ? (
              <div className="contact-error">
                {branchForm.errors.branchDescription}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="branchLocation">
              branchLocation
            </label>
            <input
              className="form-input"
              id="branchLocation"
              name="branchLocation"
              type="text"
              onChange={branchForm.handleChange}
              onBlur={branchForm.handleBlur}
              value={branchForm.values.branchLocation}
            />
            {branchForm.touched.branchLocation &&
            branchForm.errors.branchLocation ? (
              <div className="contact-error">
                {branchForm.errors.branchLocation}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="branchImage">
              branchImage
            </label>
            <input
              className="form-input"
              id="branchImage"
              name="branchImage"
              type="file"
              onChange={(event) => {
                // console.log("image", event.currentTarget.files[0]);
                branchForm.setFieldValue(
                  "branchImage",
                  event.currentTarget.files[0]
                );
              }}
            />
            {branchForm.touched.branchImage && branchForm.errors.branchImage ? (
              <div className="contact-error">
                {branchForm.errors.branchImage}
              </div>
            ) : null}
          </div>
          <button className="contact-submit" type="submit">
            Submit
          </button>
          <button
            className="contact-submit"
            onClick={async () => await deleteBranch()}
          >
            Delete
          </button>
        </form>
      </div>
    </div>
  );
};
export default AdminBranch;
