import "./browse.css";
// import browse from "../../../../Assets/Client/browse.png";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import { useSelector } from "react-redux";

const Browse = () => {
  const home = useSelector((state) => state.home);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="browse-main-container">
      {home === null ? (
        <div> LOADING </div>
      ) : (
        <>
          <div className="browser-left-container">
            <img className="browse-image" src={home.homeImage} alt="" />
            {/* <div className="fashion-video">check out our new fashions</div> */}
            {/* <Youtube embedId="rokGy0huYEA"/> */}
          </div>
          <div className="browser-right-container">
            <div className="browse-right-content">{home.homeVideoTitle}</div>
            <div
              className="browse-right-button"
              onClick={() => {
                handleClickOpen();
              }}
            >
              <PlayCircleIcon />
              Play
            </div>
          </div>

          <Dialog style={{ width: "100%" }} open={open} onClose={handleClose}>
            {/* <DialogContent style={{width:"100%"}}> */}
            <iframe
              width="600px"
              height="480"
              src={home.homeVideoUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
            {/* </DialogContent> */}
          </Dialog>
        </>
      )}
    </div>
  );
};
export default Browse;
