import { SET_DISCOUNT } from "../../actions/discount.action";

export const setDiscount = (state = null, action) => {
    switch (action.type) {
      case SET_DISCOUNT:
        return action.payload;
      default:
        return state;
    }
  };

