import "./admin-category-page.css";
import Sidebar from "../../../Components/Admin/sidebar/sidebar";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import {
  createCategory,
  createSubCategory,
} from "../../../Services/networkcall.Admin.service";
import {
  getCategory,
  getSubCategory,
} from "../../../Services/netwokcall.service";
import CategoryBox from "../../../Components/Admin/categoryBox/categoryBox";
import { useSelector } from "react-redux";

const AdminCategory = () => {
  // useEffect(() => {
  //     getCategory()
  // }, [])

  const category = useSelector((state) => state.category);
  const subcategory = useSelector((state) => state.subcategory);

  const categoryForm = useFormik({
    initialValues: {
      categoryName: "",
      categoryDescription: "",
      categoryImage: "",
    },
    // validationSchema: Yup.object({
    //     categoryName: Yup.string().max(15, "Must be 15 characters or less"),
    // }),
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
      // console.log(JSON.stringify(values, null, 2));
      const res = await createCategory(
        values.categoryName,
        values.categoryDescription,
        values.categoryImage
      );
      // console.log(res);
      if (res === "success") {
        // console.log("Category created successfully");
        alert("Category created successfully");
      }
      getCategory();
      // window.location.reload();
    },
  });

  const subCategoryForm = useFormik({
    initialValues: {
      categoryId: "",
      subcCategoryName: "",
    },
    validationSchema: Yup.object({
      subCategoryName: Yup.string().max(7, "Must be 7 characters or less"),
    }),
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
      const res = await createSubCategory(
        values.categoryId,
        values.subcCategoryName
      );
      // window.location.reload();
      // console.log(res);
      if (res === "success") {
        // console.log("Category created successfully");
        alert("SubCategory created successfully");
      }
    },
  });

  useEffect(() => {
    // console.log("form values", subCategoryForm.values.categoryId);
    getSubCategory(subCategoryForm.values.categoryId);
  }, [subCategoryForm.values.categoryId]);

  return (
    <div className="admin-category-main-container">
      <Sidebar />
      <div className="admin-category-container">
        <div className="admin-category-top-container">
          <div className="admin-category-top-left">
            <form
              className="form-container"
              onSubmit={categoryForm.handleSubmit}
            >
              <div className="contact-form-title">Create Category</div>
              <div className="contact-form-input-warp">
                <label className="label" htmlFor="categoryName">
                  Category name
                </label>
                <input
                  className="form-input"
                  id="categoryName"
                  name="categoryName"
                  type="text"
                  onChange={categoryForm.handleChange}
                  onBlur={categoryForm.handleBlur}
                  value={categoryForm.values.primaryColor}
                />
                {categoryForm.touched.primaryColor &&
                categoryForm.errors.categoryName ? (
                  <div className="contact-error">
                    {categoryForm.errors.categoryName}
                  </div>
                ) : null}
              </div>
              <div className="contact-form-input-warp">
                <label className="label" htmlFor="categoryDescription">
                  Category description
                </label>
                <input
                  className="form-input"
                  id="categoryDescription"
                  name="categoryDescription"
                  type="text"
                  onChange={categoryForm.handleChange}
                  onBlur={categoryForm.handleBlur}
                  value={categoryForm.values.primaryColor}
                />
                {categoryForm.touched.primaryColor &&
                categoryForm.errors.categoryDescription ? (
                  <div className="contact-error">
                    {categoryForm.errors.categoryDescription}
                  </div>
                ) : null}
              </div>
              <div className="contact-form-input-warp">
                <label className="label" htmlFor="categoryImage">
                  Category image
                </label>
                <input
                  className="form-input"
                  id="categoryImage"
                  name="categoryImage"
                  type="file"
                  onChange={(event) => {
                    // console.log("image", event.currentTarget.files[0]);
                    categoryForm.setFieldValue(
                      "categoryImage",
                      event.currentTarget.files[0]
                    );
                  }}
                />
                {categoryForm.touched.categoryImage &&
                categoryForm.errors.categoryImage ? (
                  <div className="about-error">
                    {categoryForm.errors.categoryImage}
                  </div>
                ) : null}
              </div>

              <button className="contact-submit" type="submit">
                Create
              </button>
            </form>
          </div>
          <div className="admin-category-top-right">
            <form
              className="form-container"
              onSubmit={subCategoryForm.handleSubmit}
            >
              <div className="contact-form-title">Create Sub Category</div>
              <div className="contact-form-input-warp">
                <label className="label" htmlFor="subcCategoryName">
                  select Category
                </label>
                <select
                  className="form-input"
                  id="categoryId"
                  name="categoryId"
                  // style={{ display:"block"}}
                  onChange={subCategoryForm.handleChange}
                  onBlur={subCategoryForm.handleBlur}
                >
                  {category === null ? (
                    <option>No category Found</option>
                  ) : (
                    category.map((category) => (
                      <option
                        key={category.categoryId}
                        value={category.categoryId}
                      >
                        {category.categoryName}
                      </option>
                    ))
                  )}
                </select>
                {subCategoryForm.touched.categoryId &&
                subCategoryForm.errors.categoryId ? (
                  <div className="contact-error">
                    {subCategoryForm.errors.categoryId}
                  </div>
                ) : null}
              </div>

              <div className="contact-form-input-warp">
                <label className="label" htmlFor="subcCategoryName">
                  Sub-Category name
                </label>
                <input
                  className="form-input"
                  id="subcCategoryName"
                  name="subcCategoryName"
                  type="text"
                  onChange={subCategoryForm.handleChange}
                  onBlur={subCategoryForm.handleBlur}
                  value={subCategoryForm.values.primaryColor}
                />
                {subCategoryForm.touched.subcCategoryName &&
                subCategoryForm.errors.subcCategoryName ? (
                  <div className="contact-error">
                    {subCategoryForm.errors.subcCategoryName}
                  </div>
                ) : null}
              </div>

              <button className="contact-submit" type="submit">
                Create
              </button>
            </form>
          </div>
        </div>
        <div className="admin-category-bottom-container">
          <div className="admin-category-bottom-left">
            {category === null ? (
              <div>No categories found</div>
            ) : (
              category.map((cat) => {
                return (
                  <CategoryBox
                    type="category"
                    categoryId={cat.categoryId}
                    subCategoryId=""
                    name={cat.categoryName}
                  />
                );
              })
            )}
          </div>
          <div className="admin-category-bottom-right">
            {subcategory === null || subcategory.length === 0 ? (
              <div>No sub-categories found</div>
            ) : (
              subcategory.map((cat) => {
                return (
                  <CategoryBox
                    type="subcategory"
                    categoryId={subCategoryForm.values.categoryId}
                    subCategoryId={cat.subCategoryId}
                    name={cat.name}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminCategory;
