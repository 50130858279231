import "./sidebar.css";
import logo from "../../../Assets/Client/logo.png";
import { useNavigate } from "react-router-dom";
import { AdminSiteMap } from "../../../Routes/Admin/siteMap";
import { useEffect } from "react";
import {
  getCategory,
  getSubCategory,
  getProduct,
} from "../../../Services/netwokcall.service";

const Sidebar = () => {
  useEffect(() => {
    getCategory();
    getSubCategory();
    getProduct();
  }, []);

  const navigate = useNavigate();

  const routeToStyle = () => {
    navigate(AdminSiteMap.AdminPage.path, { replace: true });
  };
  const routeToHome = () => {
    navigate(AdminSiteMap.AdminHomePage.path, { replace: true });
  };
  const routeToAbout = () => {
    navigate(AdminSiteMap.AdminAboutPage.path, { replace: true });
  };
  const routeToBranch = () => {
    navigate(AdminSiteMap.AdminBranchPage.path, { replace: true });
  };
  const routeToDiscount = () => {
    navigate(AdminSiteMap.AdminDiscountPage.path, { replace: true });
  };

  const routeToContact = () => {
    navigate(AdminSiteMap.AdminContactPage.path, { replace: true });
  };

  const routeToCategory = () => {
    navigate(AdminSiteMap.AdminCategoryPage.path, { replace: true });
  };
  const routeToCreateProduct = () => {
    navigate(AdminSiteMap.AdminProductPage.path, { replace: true });
  };
  const routeToReadProduct = () => {
    navigate(AdminSiteMap.AdminProductReadPage.path, { replace: true });
  };

  return (
    <div className="sidebar-main-container">
      <div className="sidebar-logo-container">
        <img className="logoImage" src={logo} alt="" />
      </div>
      <div className="sidebar-menu-container">
        <div
          onClick={() => {
            routeToStyle();
          }}
          className="sidebar-menu-item"
        >
          TEMPLATE STYLE
        </div>
        <div
          onClick={() => {
            routeToHome();
          }}
          className="sidebar-menu-item"
        >
          MODIFY HOME
        </div>
        <div className="sidebar-menu-item" onClick={() => routeToAbout()}>
          MODIFY ABOUT
        </div>
        <div className="sidebar-menu-item" onClick={() => routeToBranch()}>
          MODIFY BRANCH
        </div>
        <div className="sidebar-menu-item" onClick={() => routeToDiscount()}>
          MODIFY DISCOUNT
        </div>
        <div
          onClick={() => {
            routeToContact();
          }}
          className="sidebar-menu-item"
        >
          MODIFY CONTACT
        </div>
        <div
          onClick={() => {
            routeToCategory();
          }}
          className="sidebar-menu-item"
        >
          CREATE CATEGORY
        </div>
        <div
          onClick={() => {
            routeToCreateProduct();
          }}
          className="sidebar-menu-item"
        >
          ADD PRODUCTS
        </div>
        <div
          onClick={() => {
            routeToReadProduct();
          }}
          className="sidebar-menu-item"
        >
          PRODUCTS
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
