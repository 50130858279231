import "./category-card.css";
import { getProduct } from "../../../Services/netwokcall.service";
import { useNavigate } from "react-router-dom";
import { ClientSiteMap } from "../../../Routes/Client/siteMap";

const CategoryCard = ({ id, image, title, description }) => {
  const navigate = useNavigate();
  
  const routeToCategory = async() => {
    await getProduct(id, "")
    navigate(ClientSiteMap.ProductsPage.path, { replace: true });
  }
  return (
    <div onClick={()=>routeToCategory()} className="categorycard-main-container">
      <div className="categorycard-image-container">
        <img className="category-image" src={image} alt="" />
      </div>
      <div className="categorycard-content-container">
        <div className="categorycard-content-left">
          <div className="categorycard-content-title">{title}</div>
          <div className="categorycard-content-description">{description}</div>
        </div>
      </div>
    </div>
  );
};
export default CategoryCard;
