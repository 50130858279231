import React from "react";
import { Route, Routes } from "react-router-dom";
import { AdminSiteMap } from "./siteMap";

import AdminHome from "../../Pages/Admin/admin-home-page/admin-home.page";
import AdminHomeUpdate from "../../Pages/Admin/admin-home-update-page/admin-home-update-page";
import AdminProduct from "../../Pages/Admin/admin-product-page/admin-product";
import AdminCategory from "../../Pages/Admin/admin-category-page/admin-category-page";
import AdminProductRead from "../../Pages/Admin/admin-product-read-page/admin-product-read";
import AdminContact from "../../Pages/Admin/admin-contact-page/admin-contact-page";
import AdminAbout from "../../Pages/Admin/admin-about-page/admin-about-page";
import AdminBranch from "../../Pages/Admin/admin-branch-page/admin-branch-page";
import AdminDiscount from "../../Pages/Admin/admin-discount-page/admin-discount-page";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route exact path={AdminSiteMap.AdminPage.path} element={<AdminHome />} />
      <Route
        exact
        path={AdminSiteMap.AdminHomePage.path}
        element={<AdminHomeUpdate />}
      />
      <Route
        exact
        path={AdminSiteMap.AdminProductPage.path}
        element={<AdminProduct />}
      />
      <Route
        exact
        path={AdminSiteMap.AdminCategoryPage.path}
        element={<AdminCategory />}
      />
      <Route
        exact
        path={AdminSiteMap.AdminProductReadPage.path}
        element={<AdminProductRead />}
      />
      <Route
        exact
        path={AdminSiteMap.AdminContactPage.path}
        element={<AdminContact />}
      />
      <Route
        exact
        path={AdminSiteMap.AdminAboutPage.path}
        element={<AdminAbout />}
      />
      <Route
        exact
        path={AdminSiteMap.AdminDiscountPage.path}
        element={<AdminDiscount />}
      />
      <Route
        exact
        path={AdminSiteMap.AdminBranchPage.path}
        element={<AdminBranch />}
      />
    </Routes>
  );
};
export default AdminRoutes;
