import "./landing.css"
// import homeJSON from "./homeAPI.json"
import CustomCarosel from "../../../../Components/Client/Carousel/carosel";

const Landing = () => {
  return (      
      <section className="landing-main-container-warp">
        <div className="landing-carousel-container">
            <CustomCarosel/>
        </div>
      </section>

  );
};
export default Landing;
