import "./categoryBox.css"
import { deleteCategory, deleteSubCategory } from "../../../Services/networkcall.Admin.service";
import { getCategory } from "../../../Services/netwokcall.service";
import { getSubCategory } from "../../../Services/netwokcall.service";

const CategoryBox = ({ type, categoryId, subCategoryId, name }) => {
    const routeToDelete = async () => {
        if (type !== "category") {
            alert(`you are trying to delete ${name}`)
            await deleteSubCategory(categoryId, subCategoryId)
            await getSubCategory(categoryId)
            alert(`${name} deleted succfully`)
        }
        else {
            alert(`you are trying to delete ${name}`)
            await deleteCategory(categoryId)
            await getCategory()
            alert(`${name} deleted succfully`)
        }
    };
    return (
        <div className="category-box-main-container">
            <div className="category-name">
                Category Name : {name}
            </div>
            <button onClick={() => {
                routeToDelete();
            }} className="contact-submit">
                Delete
            </button>
        </div>
    )
}

export default CategoryBox