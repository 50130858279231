import "./admin-discount-page.css";
import Sidebar from "../../../Components/Admin/sidebar/sidebar";
import { useFormik } from "formik";
import {
  createDiscount,
  deleteDiscount,
} from "../../../Services/networkcall.Admin.service";

const AdminDiscount = () => {
  const discountForm = useFormik({
    initialValues: {
      index:"",
      discountTitle: "",
      discountDescription: "",
      discountImage: "",
    },
    onSubmit: async (values) => {
      // console.log(JSON.stringify(values, null, 2));
      alert(JSON.stringify(values, null, 2));
      await createDiscount(
        values.index,
        values.discountTitle,
        values.discountDescription,
        values.discountImage
      );
      if(values.index === ""){
        alert("rewards section created successfully")
      }
      else{
        alert("rewards updated successfully")
      }
    },
  });
  return (
    <div className="admin-discount-main-container">
      <Sidebar />
      <div className="admin-discount-container">
        <form className="form-container" onSubmit={discountForm.handleSubmit}>
          <div className="contact-form-title">Modify Discount</div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="index">
              section
            </label>
            <input
              className="form-input"
              id="index"
              name="index"
              type="text"
              onChange={discountForm.handleChange}
              onBlur={discountForm.handleBlur}
              value={discountForm.values.index}
            />
            {discountForm.touched.index &&
            discountForm.errors.index ? (
              <div className="contact-error">
                {discountForm.errors.index}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="discountTitle">
              discountTitle
            </label>
            <input
              className="form-input"
              id="discountTitle"
              name="discountTitle"
              type="text"
              onChange={discountForm.handleChange}
              onBlur={discountForm.handleBlur}
              value={discountForm.values.discountTitle}
            />
            {discountForm.touched.discountTitle &&
            discountForm.errors.discountTitle ? (
              <div className="contact-error">
                {discountForm.errors.discountTitle}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="discountDescription">
              discountDescription
            </label>
            <input
              className="form-input"
              id="discountDescription"
              name="discountDescription"
              type="text"
              onChange={discountForm.handleChange}
              onBlur={discountForm.handleBlur}
              value={discountForm.values.discountDescription}
            />
            {discountForm.touched.discountDescription &&
            discountForm.errors.discountDescription ? (
              <div className="contact-error">
                {discountForm.errors.discountDescription}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="discountImage">
              discountImage
            </label>
            <input
              className="form-input"
              idiscountAdcontact="discountImage"
              name="discountImage"
              type="file"
              onChange={(event) => {
                // console.log("image", event.currentTarget.files[0]);
                discountForm.setFieldValue(
                  "discountImage",
                  event.currentTarget.files[0]
                );
              }}
            />
            {discountForm.touched.discountImage &&
            discountForm.errors.discountImage ? (
              <div className="contact-error">
                {discountForm.errors.discountImage}
              </div>
            ) : null}
          </div>
          <button className="contact-submit" type="submit">
            Submit
          </button>
          <button
            className="contact-submit"
            onClick={async () => await deleteDiscount()}
          >
            Delete
          </button>
        </form>
      </div>
    </div>
  );
};
export default AdminDiscount;
