import React from "react";
import { Route, Routes } from "react-router-dom";
import { ClientSiteMap } from "./siteMap";
// import Header from "../../Components/Client/header/header";

import Home from "../../Pages/Client/home/home";
import Contact from "../../Pages/Client/contact/contact";
import About from "../../Pages/Client/about/about";
import Products from "../../Pages/Client/products/products";
import Branch from "../../Pages/Client/branch/branch";
import Discount from "../../Pages/Client/discount/discount";

const ClientRoutes = () => {
  return (
    <Routes>
    {/* <Header/> */}
      <Route exact path={ClientSiteMap.HomePage.path} element={<Home />} />
      <Route exact path={ClientSiteMap.ContactPage.path} element={<Contact />} />
      <Route exact path={ClientSiteMap.AboutPage.path} element={<About />} />
      <Route exact path={ClientSiteMap.ProductsPage.path} element={<Products />} />
      <Route exact path={ClientSiteMap.BranchPage.path} element={<Branch />} />
      <Route exact path={ClientSiteMap.DiscountPage.path} element={<Discount />} />
    </Routes>
  );
};
export default ClientRoutes;
