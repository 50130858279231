import "./about.css"
import Header from "../../../Components/Client/header/header"
import AboutSection1 from "./About-section-1/about-section-1"
import AboutSection2 from "./About-section-2/about-section-2"
import AboutSection3 from "./About-section-3/about-section-3"
import Footer from "../../../Components/Client/footer/footer";
import { useSelector } from "react-redux"
import {useEffect} from 'react';

const About = () => {
  const about = useSelector((state) => state.about)
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className="about-main-container">
        <Header/>
        {
          about === null ? <div> LOADING </div> : 
          <>
        <AboutSection1 image={about[0].aboutImage} title={about[0].aboutTitle} description={about[0].aboutDescription}/>
        <AboutSection2 image={about[1].aboutImage} title={about[1].aboutTitle} description={about[1].aboutDescription}/>
        <AboutSection3 image={about[2].aboutImage} title={about[2].aboutTitle} description={about[2].aboutDescription}/>
          </>
        }
        <Footer/>
    </div>
  )
}


export default About