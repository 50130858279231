import "./admin-about-page.css";
import Sidebar from "../../../Components/Admin/sidebar/sidebar";
import { useFormik } from "formik";
import {
  createAbout,
  deleteAbout,
} from "../../../Services/networkcall.Admin.service";

const AdminAbout = () => {
  const aboutForm = useFormik({
    initialValues: {
      index:"",
      aboutTitle: "",
      aboutDescription: "",
      aboutImage: "",
    },
    onSubmit: async (values) => {
      // console.log(JSON.stringify(values, null, 2));
      alert(JSON.stringify(values, null, 2));
      await createAbout(
        values.index,
        values.aboutTitle,
        values.aboutDescription,
        values.aboutImage
      );
      if(values.index === ""){
        alert("about section created successfully")
      }
      else{
        alert("about updated successfully")
      }
    },
  });
  return (
    <div className="admin-about-main-container">
      <Sidebar />
      <div className="admin-about-container">
        <form className="form-container" onSubmit={aboutForm.handleSubmit}>
          <div className="contact-form-title">Modify About</div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="index">
              section
            </label>
            <input
              className="form-input"
              id="index"
              name="index"
              type="text"
              onChange={aboutForm.handleChange}
              onBlur={aboutForm.handleBlur}
              value={aboutForm.values.index}
            />
            {aboutForm.touched.index && aboutForm.errors.index ? (
              <div className="contact-error">{aboutForm.errors.index}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="aboutTitle">
              aboutTitle
            </label>
            <input
              className="form-input"
              id="aboutTitle"
              name="aboutTitle"
              type="text"
              onChange={aboutForm.handleChange}
              onBlur={aboutForm.handleBlur}
              value={aboutForm.values.aboutTitle}
            />
            {aboutForm.touched.aboutTitle && aboutForm.errors.aboutTitle ? (
              <div className="contact-error">{aboutForm.errors.aboutTitle}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="aboutDescription">
              aboutDescription
            </label>
            <input
              className="form-input"
              id="aboutDescription"
              name="aboutDescription"
              type="text"
              onChange={aboutForm.handleChange}
              onBlur={aboutForm.handleBlur}
              value={aboutForm.values.aboutDescription}
            />
            {aboutForm.touched.aboutDescription &&
            aboutForm.errors.aboutDescription ? (
              <div className="contact-error">
                {aboutForm.errors.aboutDescription}
              </div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="aboutImage">
              aboutImage
            </label>
            <input
              className="form-input"
              id="aboutImage"
              name="aboutImage"
              type="file"
              onChange={(event) => {
                // console.log("image", event.currentTarget.files[0]);
                aboutForm.setFieldValue(
                  "aboutImage",
                  event.currentTarget.files[0]
                );
              }}
            />
            {aboutForm.touched.aboutImage && aboutForm.errors.aboutImage ? (
              <div className="about-error">{aboutForm.errors.aboutImage}</div>
            ) : null}
          </div>
          <button className="contact-submit" type="submit">
            Submit
          </button>
          <div
            className="contact-submit"
            onClick={async () => await deleteAbout()}
          >
            Delete
          </div>
        </form>
      </div>
    </div>
  );
};
export default AdminAbout;
