import "./admin-product.css"
import Sidebar from "../../../Components/Admin/sidebar/sidebar"
import { useFormik } from "formik";
// import * as Yup from "yup";
import { createProduct } from "../../../Services/networkcall.Admin.service";
import { useSelector } from "react-redux";
import { getSubCategory } from "../../../Services/netwokcall.service";
import { useEffect, useState } from "react";

const AdminProduct = () => {
  const category = useSelector((state) => state.category)
  const subcategory = useSelector((state) => state.subcategory)
  const [image, setImage] = useState({})
  const productForm = useFormik({
    initialValues: {
      // productImage: {},
      productName: "",
      productDescription: "",
      productCategoryId: "",
      productSubCategoryId: ""
    },
    onSubmit: async(values) => {
      // console.log(JSON.stringify(values, null, 2), image);
      alert(JSON.stringify(values, null, 2));
      const res = await createProduct(values.productName, values.productDescription, values.productCategoryId, values.productSubCategoryId, image)
      // console.log(res);
      if(res==="success"){
        alert("product created successfully")
      }
    },
  });

  useEffect(() => {
    // console.log("form values", productForm.values.productCategoryId);
    getSubCategory(productForm.values.productCategoryId)
  }, [productForm.values.productCategoryId])

  return (
    <div className="admin-product-main-container">
      <Sidebar />
      <div className="admin-product-container">
        <form encType="multipart/form-data" className="form-container" onSubmit={productForm.handleSubmit}>
          <div className="contact-form-title">Add Products</div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="productImage">
              productImage
            </label>
            <input
              className="form-input"
              id="productImage"
              name="productImage"
              type="file"
              onChange={(event) => {
                // console.log("image", event.currentTarget.files[0]);
                setImage(event.currentTarget.files[0])
                // productForm.setFieldValue("productImage", event.currentTarget.files[0]);
              }}
            />
            {productForm.touched.productImage && productForm.errors.productImage ? (
              <div className="contact-error">{productForm.errors.productImage}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="productName">
              productName
            </label>
            <input
              className="form-input"
              id="productName"
              name="productName"
              type="text"
              onChange={productForm.handleChange}
              onBlur={productForm.handleBlur}
              value={productForm.values.productName}
            />
            {productForm.touched.productName && productForm.errors.productName ? (
              <div className="contact-error">{productForm.errors.productName}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="productDescription">
              productDescription
            </label>
            <input
              className="form-input"
              id="productDescription"
              name="productDescription"
              type="text"
              onChange={productForm.handleChange}
              onBlur={productForm.handleBlur}
              value={productForm.values.productDescription}
            />
            {productForm.touched.productDescription && productForm.errors.productDescription ? (
              <div className="contact-error">{productForm.errors.productDescription}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="productCategoryId">
              select Category
            </label>
            <select
              className="form-input"
              id="productCategoryId"
              name="productCategoryId"
              // style={{ display:"block"}}
              onChange={productForm.handleChange}
              onBlur={productForm.handleBlur}
            >
              {(category === null || category.length === 0) ? <option>no category found</option> : category.map(category =>
                <option key={category.categoryId} value={category.categoryId}>{category.categoryName}</option>)}
            </select>
            {productForm.touched.productCategoryId && productForm.errors.productCategoryId ? (
              <div className="contact-error">{productForm.errors.productCategoryId}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp">
            <label className="label" htmlFor="productSubCategoryId">
              select Sub-Category
            </label>
            <select
              className="form-input"
              id="productSubCategoryId"
              name="productSubCategoryId"
              // style={{ display:"block"}}
              onChange={productForm.handleChange}
              onBlur={productForm.handleBlur}
            >
              <option key="0" value="0">Not selected</option>
              {(subcategory === null || subcategory.length === 0) ? <option>no subcategory found</option> : subcategory.map(category =>
                <option key={category.subcategoryId} value={category.subcategoryId}>{category.name}</option>)}
            </select>
            {productForm.touched.productSubCategoryId && productForm.errors.productSubCategoryId ? (
              <div className="contact-error">{productForm.errors.productSubCategoryId}</div>
            ) : null}
          </div>

          <button className="contact-submit" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}
export default AdminProduct